import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "../AxiosClient";

export const addLanguageAsync = createAsyncThunk(
  "admin/addLanguage",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/addLanguage`, payload, toolkit);
  }
);

export const getAllLanguageAsync = createAsyncThunk(
  "admin/getAllLanguage",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/getAllLanguage?page=${payload.page || ""}&limit=${
        payload.limit || ""
      }&search=${payload.search || ""}`,
      [],
      toolkit
    );
  }
);

export const getLanguageByIdAsync = createAsyncThunk(
  "admin/getLanguageById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/getLanguageById/${payload}`,
      payload,
      toolkit
    );
  }
);

export const updateLanguageByIdAsync = createAsyncThunk(
  "admin/updateLanguageById",
  async (payload, toolkit) => {
    return await AxiosClient("PUT", `/updateLanguageById`, payload, toolkit);
  }
);

export const deleteLanguageByIdAsync = createAsyncThunk(
  "admin/deleteLanguageById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/deleteLanguageById/${payload}`,
      [],
      toolkit
    );
  }
);
