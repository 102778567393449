import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getAllLanguageAsync,
  addLanguageAsync,
  getLanguageByIdAsync,
  updateLanguageByIdAsync,
  deleteLanguageByIdAsync,
} from "./languages.async";

const initialState = {
  languagesLoader: false,
  languagesadd: [],
  allLanguages: [],
  languageById: [],
  updateLanguageById: [],
  deleteLanguagesById: [],
};

export const languagesSlice = createSlice({
  name: "state",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        getAllLanguageAsync.pending,
        addLanguageAsync.pending,
        getLanguageByIdAsync.pending,
        updateLanguageByIdAsync.pending,
        deleteLanguageByIdAsync.pending
      ),
      (state) => {
        state.languagesLoader = true;
      }
    );
    builder.addMatcher(
      isAnyOf(getAllLanguageAsync.fulfilled),
      (state, action) => {
        state.languagesLoader = false;
        state.allLanguages = action.payload;
      }
    );
    builder.addMatcher(isAnyOf(addLanguageAsync.fulfilled), (state, action) => {
      state.languagesLoader = false;
      state.languagesadd = action.payload;
    });
    builder.addMatcher(
      isAnyOf(getLanguageByIdAsync.fulfilled),
      (state, action) => {
        state.languagesLoader = false;
        state.languageById = action.payload.data;
      }
    );
    builder.addMatcher(
      isAnyOf(updateLanguageByIdAsync.fulfilled),
      (state, action) => {
        state.languagesLoader = false;
        state.updateLanguageById = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(deleteLanguageByIdAsync.fulfilled),
      (state, action) => {
        state.languagesLoader = false;
        state.deleteLanguagesById = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(
        getAllLanguageAsync.rejected,
        addLanguageAsync.rejected,
        getLanguageByIdAsync.rejected,
        updateLanguageByIdAsync.rejected,
        deleteLanguageByIdAsync.rejected
      ),
      (state, action) => {
        state.languagesLoader = false;
      }
    );
  },
  reducers: {
    emptylanguages: (state) => initialState,
    emptyById: (state) => {
      state.languageById = [];
    },
  },
});

export const { emptylanguages, emptyById } = languagesSlice.actions;

export default languagesSlice.reducer;
